@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

* {
    font-family: var(--body-font) !important;
}

$primary:       #727cf5;
$secondary:       #6c757d;
$success:       $teal-500;
$warning:       $yellow-500;
$light:       $gray-100;
//$light:       #F7F6FB;
$danger:       $red-500;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
);

.text-ellipsis {
    text-overflow: ellipsis !important;
}

.btn-light {
    color: #000;
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
}

.w-20 {
    width: 20% !important;
}

.text-light-s {
    color: #6c757d !important;
}

.text-soft {
    color: #939ea7 !important;
    font-weight: 400;
    font-size: small;
}

/* finally, import Bootstrap */
@import "../node_modules/bootstrap";

.card {
    border: none !important;
    //-webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%) !important;
    //box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%) !important;
}

.fc-day-separator {
    border-right: solid #4723D9 1px !important;
}

#root, .App, .app-container, .page-container, .page {
    //height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 0;
}

.app-container{
    position: relative;
    background-color: var(--white-color);
}

#offcanvasRight .form-control, #offcanvasRight .form-select, #offcanvasRight .select__control {
    border-radius: 0 !important;
    background-color: #f6f7fb !important;
    border: none !important;
}

.offcanvas-end .form-control, .offcanvas-end .form-select, .offcanvas-end .select__control {
    border-radius: 0 !important;
    background-color: #f6f7fb !important;
    border: none !important;
}

#offcanvasRight .input-group-text {
    background-color: transparent !important;
    border: none !important;
}

.form-control, .form-select, .select__control, .input-group-text {
    //border: none !important;
}

.page-container{
    width: 100%;
    transition: 0.5s;
    position: relative;
}

a {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
}

a, a:hover {
    text-decoration: none !important;
}

.react-datepicker-wrapper{
    display: block !important;
    flex: 1 0;
}

.min-h-100{
    min-height: 100% !important;
}

.input-table{
    text-align: center;
    max-width: 30px;
}

.input-table:focus-visible{
    border: none !important;
    background-color: white !important;
    outline: none !important;
}

.sivan_form{
    label.bg-blue{
        padding: 5px;
        background-color: $blue-100;
        text-transform: uppercase;
        font-weight: 600;
        width: 100%;
    }
}

.fullcalendar-resource-icon{
    font-size: 1.5rem;
}



.fc-icon-fa{
    background-color: transparent !important;
    color: black !important;
}

.fc-icon{
    height: auto !important;
    width: auto !important;
}

.planning-filters .basic-single{
    min-width: 250px;
    white-space: nowrap;
    margin: auto auto;
}

.calendar-view-selector{
    width: 10rem;
}

.calendar-view-container .dropdown-toggle::after {
    position: absolute;
    top: 1rem;
    right: 0.5rem;
}

.dropdown-toggle-no-after-content:after{
    content: none !important;
}
.no-content:after, .no-content:before{
    content: none !important;
}

.template-calendar .fc .fc-datagrid-cell-cushion{
    overflow: visible !important;
}

.dropdown-menu-message-reaction{
    min-width: 12rem;
    text-align: center;
}

.dropdown-toggle-plus-before:before{
    content: '+' !important;
    border: none !important;
}

.left-0 {
    left: 0 !important;
}

.list-group-sidebar-left{
    background-color: white;
    max-width: 300px;
    height: 100%;
}

.sticky-table {
    position: relative;
    border-collapse: collapse;
    thead th {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 1;
        background-color: white;
    }
}

.app-container{
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: auto;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
}

.height-header {
    height: calc(100vh - 4rem) !important;
}

.color-picker{
    width: 100% !important;
    overflow-y: hidden;
    overflow-x: auto;
}

h4, .h4, .table .th {
    //margin-bottom: 0.5rem;
    text-transform: uppercase;
    letter-spacing: .02em;
    font-size: .9rem;
    margin-top: 0;
}

.salary-initials {
    height: 28px;
    width: 28px;
    position: relative;
    outline: none;
    vertical-align: middle;
    border-radius: 50%;
    transition: box-shadow 100ms ease 0s;
    color: white;
    display: inline-block;
    background-color: var(--bs-primary);
}

.color-circle {
    float: right;
    text-align: center;
    line-height: 28px;
    height: 28px;
    width: 28px;
    div{
        background: transparent;
        height: 100%;
        width: 100%;
        cursor: pointer;
        position: relative;
        outline: none;
        border-radius: 50%;
        transition: box-shadow 100ms ease 0s;
    }
}

.fc a{
    color: var($blue-500);
}

.fc{
    background-color: white;
}

#viewTitleContainer {
    text-align: center;
    width: 180px;
}

.listSelector {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    width: 100%;
    background-color: white;
    padding: 15px 15px;
    z-index: 100;
}

.input-group-text{
    background-color: #f6f7fb;
}

.bg-sprinkle{
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.booking {
    overflow: hidden;
    text-overflow: ellipsis;
}

.react-datepicker__header {
    background-color: white !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: var(--bs-primary) !important;
    color: white !important;
}

.bg-blue-s {
    background-color: #4723D9 !important;
}
.text-blue-s {
    color: #4723D9 !important;
}

.react-datepicker__year-select, .react-datepicker__month-select {
    padding: 5px;
    border: none;
}

.react-datepicker__year-select:focus, .react-datepicker__month-select:focus {
    border: none !important;
}

table thead th{
    color: var(--bs-gray-dark);
}

.react-datepicker-popper{
    z-index: 100 !important;
    background-color: white;
}

.fc-event-less-opacity{
    opacity: 0.3 !important;
}
.fc-timeline-event-harness{
    padding: 0 1px;
}
.fc-timeline-event {
    margin-bottom: 3px !important;
}

.css-1rhbuit-multiValue {
    background-color: white !important;
}

.select__multi-value__label{
    color: var(--bs-primary) !important;
}

.fc-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 1000;
    text-align: center;
}

.nav-tabs {
    background-color: white !important;
    border: none !important;
    .nav-link{
        border-left: none !important;
        border-right: none !important;
        border-top: none !important;
    }
    .nav-link:hover, .active {
        border-bottom: 2px solid var(--bs-primary) !important;
    }
}

.fc-datagrid-cell-main{
    width: 100%;
}

.fc {
    padding: 0;
}

.fc-timeline-event {
    border-radius: 5px !important;
    padding: 2px !important;
}
//
//.fc-resource-group, .fc-cell-shaded{
//    background: #4723d929 !important;
//}

.bg-primary-light {
    background-color: #0D6EFD4D;
}

.template-add-booking-form, .timetable-add-booking-form {
    .react-datepicker__day--disabled {
        display: none;
    }
    .react-datepicker__day{
        font-size: 1rem !important;
        width: 2.5rem !important;
        line-height: 2.5rem !important;
    }
    .react-datepicker__day-names{
        display: none;
    }
    .react-datepicker__header {
        display: none;
    }
    .react-datepicker-time__input{
        padding: 5px;
        border: none;
        font-size: 1rem;
        border-radius: 0;
        background-color: #f6f7fb !important;
        width: 100% !important;
        margin: 0 0 !important;
        input {
            width: 100% !important;
        }
        input:focus-visible {
            outline: none !important;
        }
    }
    .react-datepicker-time__caption{
        display: none !important;
    }
    .react-datepicker-time__input-container{
        width: 100%;
    }
    .react-datepicker__input-time-container{
        margin: 0 auto;
        padding: 10px;
        width: 100%;
    }
}

.bootstrap-custom-select-list-group {
    max-height: 250px;
}

.bootstrap-custom-select{
    .search-input.is-loading{
        padding-right: calc(1.5em + .75rem);
        //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right calc(.375em + .1875rem) center;
        background-size: calc(.75em + .375rem) calc(.75em + .375rem);
    }
}

.table-counter{
    th.shad {

    }
}

.placeholder div{
    background:#E8E8E8;
}

.placeholder .square{
    float:left;
    width: 90px;
    height:56px;
    margin:0 0 10px;
}

.placeholder .line{
    height:1rem;
    padding: 0 25px
}

.placeholder .circle{
    width: 30px;
    height:30px;
    border-radius:15px;
    margin: 0 auto;
}

/*
  --------------
  Pulse effect animation
  Activated by adding a '.pulse' class to the placeholder
  --------------
*/

.placeholder.pulse div{
    animation: pulse 1s infinite ease-in-out;
    -webkit-animation:pulse 1s infinite ease-in-out;
}

@keyframes pulse
{
    0%{
        background-color: rgba(165,165,165,.1);
    }
    50%{
        background-color: rgba(165,165,165,.3);
    }
    100%{
        background-color: rgba(165,165,165,.1);
    }
}

@-webkit-keyframes pulse
{
    0%{
        background-color: rgba(165,165,165,.1);
    }
    50%{
        background-color: rgba(165,165,165,.3);
    }
    100%{
        background-color: rgba(165,165,165,.1);
    }
}

/*
  --------------
  Wave effect animation
  Activated by adding a '.wave' class to the placeholder
  --------------
*/

.placeholder.wave div{
    animation: wave 1s infinite linear forwards;
    -webkit-animation:wave 1s infinite linear forwards;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
}

@keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

@-webkit-keyframes wave{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.table-counter {

}

.fc-day .fc-timeline-slot-cushion {
    width: 100%;
}

.radius-0 {
    border-radius: 0 !important;
}
.radius-bottom-end-0 {
    border-bottom-right-radius: 0 !important;
}

.br-5 {
    border-radius: 10px;
}
.br-0 {
    border-radius: 0 !important;
}

.radius-bottom-start-0 {
    border-bottom-left-radius: 0 !important;
}

.fs-10 {
    font-size: 10px !important;
}

.z10 {
    z-index: 10 !important;
}
.z100 {
    z-index: 100 !important;
}

.gold {
    color: #FFD700;
}
.auth_logo{
    width: 30%;
    height: auto;
    margin: 10px auto;
}
.card_logo{
    height: 2rem;
    width: auto;
}

.digit-input {
    height: 5rem;
    width: 1em;
    line-height: 1;
    margin: .1em;
    padding: 8px 0 4px;
    font-size: 2.65em;
    text-align: center;
    border-radius: 4px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.fc-event.absent {
    background-color: #e9ecef;
}

.fc-event.absent.accepted {
    background-color: #e9ecef !important;
    border-color: #28a745 !important;
    border-width: 2px;
}

.fc-event.absent.pending {
    background-color: #e9ecef !important;
    border-color: #ffc107 !important;
    border-width: 2px;
}

.fc-event.absent.refused {
    background-color: #e9ecef !important;
    border-color: #dc3545 !important;
    border-width: 2px;
}

.fc-event.jf {
    background-color: white !important;
    border-color: #28a745 !important;
    border-width: 2px;
}

.fc-event.jf-worked {
    border-color: #28a745 !important;
    border-width: 2px;
}

.btn-outline-primary:hover, .btn-check:checked + .btn-outline-primary {
    color: white;
}

#singleContainer {
    height: 100%;
    overflow: auto;
}

*::-webkit-scrollbar-thumb {
    background: #cfcfcf;
    border-radius: 5px;
}

.fc-timeline-event-keepNote {
    font-size: 12px !important;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal !important;
}


.cursor-pointer {
    cursor: pointer !important;
}

.fs-min {
    font-size: 8px !important;
}

.fadeOut {
    opacity:0;
    transition: 0.5s;
}

.mh-400{
    min-height: 400px !important;
}

.zIndex-100{
    z-index: 100 !important;
}

.fc-highlight-primary {
    opacity: 0.5;
}

.stripes {
    background:
            /* On "top" */
            repeating-linear-gradient(
                            45deg,
                            transparent,
                            transparent 10px,
                            #ccc 10px,
                            #ccc 20px
            ),
                /* on "bottom" */
            linear-gradient(
                            to bottom,
                            #eee,
                            #999
            ) !important;
}

.hover-primary{
    transition: 0.3s;
}
.hover-primary:hover{
    color: var(--bs-primary) !important;
}

.offcanvas-end.offcanvas-event {
    width: 40%;
    z-index: 3080;
    .offcanvas-header{
        width: 100%;
    }
}

.offcanvas-end {
    width: 50%;
    z-index: 3080;
    .offcanvas-header{
        width: 100%;
    }
}

@media (max-width: 991.68px) {
    .offcanvas-end {
        width: 100% !important;
    }
}


.bg-task-grey {
    background-color: #dee2e6 !important;
    border-color: #dee2e6 !important;

    .fc-event-title, .fc-event-time {
        color: #000 !important;
    }
}

.calendar-toolbar .btn {
    font-size: 0.8rem !important;
}

.cell-hover-elements-container {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;

    > div {
        flex: 1;
        margin: 1px;
        color: transparent;
        text-align: center;
        display: flex;
        width: 100%;
        align-items: center;

        &:hover {
            background-color: $light;
            color: black;
        }

        > div {
            flex-grow: 1;
        }
    }
}

.dropend-nav {
    position: relative;
}

.dropend-nav-menu{
    display: none;
    background-color: white;
    padding: 0.5rem !important;
}

.dropend-nav-menu .nav_link_children{
    padding: 0.5rem !important;
}

.l-navbar .dropend-nav-menu.hovered {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(68px, 0px);
}

@media screen and (min-width: 768px) {
    .l-navbar.nav_show .dropend-nav-menu.hovered {
        display: block;
        position: absolute;
        inset: 0px auto auto 0px;
        margin: 0px;
        transform: translate(calc(68px + 156px), 0px);
    }
}

.fc-timeline-event {
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fc-ratio-s {
    .fc .fc-timeline-slot-cushion{
        font-size: 7px;
        padding: 1px 2px !important;
    }

    .fc-resource {
        font-size: 10px !important;
    }

    .fc-timeline-event {
        font-size: 10px !important;
        padding: 0 0 !important;
    }

    .fc-timeline-event-harness {
        padding: 0 0 !important;
    }
}

.fc-ratio-m {

    .fc .fc-timeline-slot-cushion{
        font-size: 10px;
        padding: 2px 3px;
    }


    .fc-resource {
        font-size: 10px !important;
    }

    .fc-timeline-event {
        font-size: 10px !important;
    }
}

.fc-ratio-l {
    .fc .fc-timeline-slot-cushion{
        font-size: 12px;
        padding: 4px 5px;
    }


    .fc-resource {
        font-size: 12px !important;
    }

    .fc-timeline-event {
        font-size: 12px !important;
    }
}

.stripedBackground {
    background: repeating-linear-gradient(
                    -55deg,
                    $gray-100,
                    $gray-100 10px,
                    $gray-300 10px,
                    $gray-300 20px
    ) !important;
}

.stripedBackgroundSuccess {
    background: repeating-linear-gradient(
                    -55deg,
                    $gray-100,
                    $green-100 10px,
                    $gray-300 10px,
                    $green-300 20px
    ) !important;
}