@import url('https://fonts.googleapis.com/css2?family=Arimo:wght@500&display=swap');

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color: #4723D9;
  --first-color-light: #727cf5;
  //--first-color-light: #AFA5D9;
  --white-color: #F7F6FB;
  --body-font: 'Arimo', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 1021
}

*,
::before,
::after {
  box-sizing: border-box
}

.app-container {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 0;
  font-family: var(--body-font) !important;
  font-size: var(--normal-font-size);
  transition: .5s;
  background-color: var(--white-color);
}

a {
  text-decoration: none
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: white;
  z-index: var(--z-fixed);
  transition: .5s
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden
}

.header_img img {
  width: 40px
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: .5rem 1rem 0 0;
  transition: .5s;
  z-index: var(--z-fixed)
}

.nav-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dropend{
    width: 100%;
  }
  .dropdown-menu{
    min-width: 13rem;
    //background-color: var(--first-color);
    .dropdown-item.nav-active, .dropdown-item:nav-active{
      background-color: var(--first-color) !important;
    }
  }
}

.nav_logo,
.nav_link,
.nav_link_children {
  width: 100%;
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: .5rem 0 .5rem 1.5rem
}

.nav_logo {
  margin-bottom: 2rem;
  overflow: hidden;
  white-space: nowrap;
}

.nav_logo-icon {
  height: 2rem;
  width: auto;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}


.nav_link_children {
  color: var(--first-color-light);
  transition: .3s
}
.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: .3s;
  overflow: hidden;
  white-space: nowrap;
}

.nav_link:hover {
  color: var(--white-color)
}

.nav_icon {
  height: 1.25rem;
  width: auto;
}
.nav_logo-name{
  //display: none;
  transition: 0.5s;
  padding-left: 0.5rem;
}
.nav_name{
  //display: none;
  transition: 0.5s;
  padding-left: 0.5rem;
}

.app-p {
  padding-left: calc(var(--nav-width))
}

.nav-active {
  color: var(--white-color)
}

.nav_link .list-group {
  display: none;
}

.nav-active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color)
}

.height-100 {
  height: 100vh
}

.nav_show{
  left: 0;
}

@media screen and (min-width: 768px) {
  .app-container {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: var(--nav-width)
  }

  //.nav_show {
  //  .nav_logo-name{
  //    display:block;
  //  }
  //  .nav_name{
  //    display:block;
  //  }
  //}

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
  }

  .header_img {
    width: 40px;
    height: 40px
  }

  .header_img img {
    width: 45px
  }

  .l-navbar {
    left: 0;
    padding: 1rem 0;
  }

  .nav_show {
    width: calc(var(--nav-width) + 156px)
  }

  .app-p {
    padding-left: calc(var(--nav-width) + 156px)
  }
}

.page-title-container{
  margin-right: auto;
  padding-left: 1rem;
}

nav.breadcrumb {
  --bs-breadcrumb-divider: '>';
}
.page-title {
  font-size: 2rem;
  text-transform: capitalize;
  vertical-align: center;
}

.page-under-title {
  font-size: 1.5rem;
  text-transform: capitalize;
  vertical-align: center;
}
