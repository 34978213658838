#offcanvasRight {
    width: 50%;
    z-index: 3080;
    .offcanvas-header{
        width: 100%;
    }
}

@media (max-width: 991.68px) {
    #offcanvasRight {
        width: 100% !important;
    }
}

