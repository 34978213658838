.channel-container {
    height: calc(100vh - 4rem);
    overflow: hidden;
    .top-box {
        height: 6vh;
    }
    .message-box {
        height: calc(100% - 26vh);
        overflow-y: auto;
        .body {
            white-space: pre-line;
        }
    }
    .input-box {
        height: 20vh;
    }
}
.display-hover{
    opacity: 0;
}

.display-hover:hover{
    opacity: 1 !important;
}

//.messagebox{
//  height: calc(100vh - 4rem);
//  overflow: hidden;
//  display: flex;
//  background-color: white;
//  flex-flow: column;
//  .title-container{
//    height: 10vh;
//    background-color: white;
//    width: 100%;
//    padding: 10px;
//  }
//  .message-container{
//    overflow-wrap: break-word;
//    padding: 15px 15px;
//    flex: 1 1;
//    overflow-y: scroll;
//    width: 100%;
//    .dropdown-toggle:before{
//      content: none !important;
//    }
//    .message.right{
//      border-bottom-right-radius: 0 !important;
//      margin-left: auto;
//    }
//    .message.left{
//      border-bottom-left-radius: 0 !important;
//      margin-right: auto;
//    }
//    .message.sending{
//      background-color: #74aaff;
//      border: solid #0d6efd 1px;
//    }
//    .message{
//      max-width: 80%;
//      margin-bottom: 12px;
//      border: solid #0d6efd 1px;
//      background-color: #0d6efd;
//      border-radius: 10px;
//      padding: 5px 10px;
//      color: white;
//      .body{
//        padding: 10px;
//      }
//      .caption{
//        font-size: 10px;
//        padding: 3px 3px;
//      }
//      .message-reactions-container {
//        position: relative;
//        bottom: -15px;
//        float: right;
//        i{
//          font-size: 12px;
//          border-radius: 50%;
//          background: white;
//          padding: 4px;
//        }
//        .fa-smile{
//          color: gold;
//          border: solid gold 1px;
//        }
//        .fa-thumbs-up{
//          color: #0d6efd;
//          border: solid #0d6efd 1px;
//        }
//        .fa-thumbs-down{
//          color: darkred;
//          border: solid darkred 1px;
//        }
//        .fa-heart{
//          color: red;
//          border: solid red 1px;
//        }
//        .fa-laugh-squint{
//          color: gold;
//          border: solid gold 1px;
//        }
//      }
//    }
//    .actions{
//      opacity: 0;
//      .icons{
//        .fa-smile.active{
//          color: gold;
//        }
//        .fa-smile:hover{
//          color: gold;
//        }
//        .fa-thumbs-up.active{
//          color: #0d6efd;
//        }
//        .fa-thumbs-up:hover{
//          color: #0d6efd;
//        }
//        .fa-thumbs-down.active{
//          color: darkred;
//        }
//        .fa-thumbs-down:hover{
//          color: darkred;
//        }
//        .fa-heart.active{
//          color: red;
//        }
//        .fa-heart:hover{
//          color: red;
//        }
//        .fa-laugh-squint.active{
//          color: gold;
//        }
//        .fa-laugh-squint:hover{
//          color: gold;
//        }
//      }
//    }
//    .actions:hover{
//      opacity: 1;
//    }
//  }
//  .message-writer-container{
//    width: 100%;
//    margin: 0 auto;
//    background-color: white;
//    textarea{
//      background-color: white !important;
//      border: solid #0d6efd 1px !important;
//      border-radius: 5px;
//      height: 100%;
//    }
//  }
//
//}
