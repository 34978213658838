.custom-table thead tr, .custom-table thead th {
  border-top: none;
  border-bottom: none !important; }
.custom-table tbody th, .custom-table tbody td {
  color: #777;
  font-weight: 400;
  padding-bottom: 20px;
  padding-top: 20px;
  font-weight: 300; }
.custom-table tbody th small, .custom-table tbody td small {
  color: #b3b3b3;
  font-weight: 300; }
.custom-table tbody .persons {
  padding: 0;
  margin: 0; }
.custom-table tbody .persons li {
  padding: 0;
  margin: 0 0 0 -15px;
  list-style: none;
  display: inline-block; }
.custom-table tbody .persons li a {
  display: inline-block;
  width: 36px; }
.custom-table tbody .persons li a img {
  border-radius: 50%;
  max-width: 100%; }
.custom-table tbody tr th, .custom-table tbody tr td {
  position: relative;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
.custom-table tbody tr th:before, .custom-table tbody tr th:after, .custom-table tbody tr td:before, .custom-table tbody tr td:after {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  content: "";
  left: 0;
  right: 0;
  position: absolute;
  height: 1px;
  background: #bfbfbf;
  width: 100%;
  opacity: 0;
  visibility: hidden; }
.custom-table tbody tr th:before, .custom-table tbody tr td:before {
  top: -1px; }
.custom-table tbody tr th:after, .custom-table tbody tr td:after {
  bottom: -1px; }
.custom-table tbody tr:hover th, .custom-table tbody tr:hover td {
  background: rgba(0, 0, 0, 0.03); }
.custom-table tbody tr:hover th:before, .custom-table tbody tr:hover th:after, .custom-table tbody tr:hover td:before, .custom-table tbody tr:hover td:after {
  opacity: 1;
  visibility: visible; }
.custom-table tbody tr.active th, .custom-table tbody tr.active td {
  background: rgba(0, 0, 0, 0.03); }
.custom-table tbody tr.active th:before, .custom-table tbody tr.active th:after, .custom-table tbody tr.active td:before, .custom-table tbody tr.active td:after {
  opacity: 1;
  visibility: visible; }

/* Custom Checkbox */
.control {
  display: block;
  position: relative;
  margin-bottom: 25px;
  cursor: pointer;
  font-size: 18px; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 2px solid #ccc;
  background: transparent; }

.control--radio .control__indicator {
  border-radius: 50%; }

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  border: 2px solid #007bff; }

.control input:checked ~ .control__indicator {
  border: 2px solid #007bff;
  background: #007bff; }

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
  border: 2px solid #ccc; }

.control__indicator:after {
  font-family: 'icomoon';
  content: '\e5ca';
  position: absolute;
  display: none; }

.control input:checked ~ .control__indicator:after {
  display: block;
  color: #fff; }

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -52%);
  -ms-transform: translate(-50%, -52%);
  transform: translate(-50%, -52%); }

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b; }

.control--checkbox input:disabled:checked ~ .control__indicator {
  background-color: #007bff;
  opacity: .2;
  border: 2px solid #007bff; }